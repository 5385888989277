<template>
  <section>
    <t-form ref="form" @submit.prevent>
      <DisciplineFormPart :is-api-validated="false" @result="transition" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import DisciplineFormPart, {
  PersonAndFamily,
  HouseAndMortgage,
  FoundationAndCompany,
} from 'chimera/officeOfNotary/components/form/parts/discipline/DisciplineFormPart'
import HouseAndMortgageDeedPage from '~/pages/offertes-aanvragen/akte-huis-en-hypotheek'
import PersonAndFamilyDeedPage from '~/pages/offertes-aanvragen/akte-persoon-en-familie'
import FoundationAndCompanyDeedPage from '~/pages/offertes-aanvragen/akte-stichting-en-bedrijf'

export default {
  name: 'DisciplineFormStep',

  components: {
    DisciplineFormPart,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  methods: {
    /**
     * Transition
     *
     * @param {object} result
     * @param {object} result.value
     */
    transition({ value } = {}) {
      let page

      switch (value) {
        case PersonAndFamily:
          page = PersonAndFamilyDeedPage
          break
        case HouseAndMortgage:
          page = HouseAndMortgageDeedPage
          break
        case FoundationAndCompany:
          page = FoundationAndCompanyDeedPage
          break
        default:
          page = PersonAndFamilyDeedPage
          this.$eventBus.emitErrorAppErrorEvent(
            new Error(
              'DisciplineFormStep transition: could not match given value to destination page. Default used.',
            ),
            { value },
          )
      }

      this.routeTo(page)
    },
  },
}
</script>
