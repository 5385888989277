<template>
  <section class="perks__container">
    <div id="perks" v-track-visibility.once class="perks__text-container">
      <h2 class="perks__title">
        {{ title }}
      </h2>
      <div v-if="subtitle" class="perks__subtitle">
        {{ subtitle }}
      </div>
    </div>

    <div class="perks__image-container">
      <Lottie
        id="perks-animatie"
        v-track-visibility.once
        :options="perksAnimationOptions"
        class="animation"
      />
    </div>
  </section>
</template>

<script>
import Lottie from 'chimera/all/components/Lottie'

export default {
  name: 'Perks',

  components: {
    Lottie,
  },

  props: {
    // eslint-disable-next-line vue/require-default-prop
    animation: {
      type: Object,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      default: '',
    },
  },

  computed: {
    /**
     *
     * @returns {{animationData: *}}
     */
    perksAnimationOptions() {
      return {
        animationData: this.animation,
      }
    },
  },
}
</script>
