<template>
  <AbstractFormPart :title="question">
    <DisciplineField :buttons="choices" v-on="$listeners" />
  </AbstractFormPart>
</template>

<script>
import DisciplineField from 'chimera/all/components/form/fields/discipline/DisciplineField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export const PersonAndFamily = 'Persoon en familie'
export const HouseAndMortgage = 'Huis en hypotheek'
export const FoundationAndCompany = 'Stichting en bedrijf'

export default {
  name: 'DisciplineFormPart',

  components: {
    DisciplineField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(PersonAndFamily, 'Persoon en familie', PersonAndFamily),
        new Selectable(HouseAndMortgage, 'Huis en hypotheek', HouseAndMortgage),
        new Selectable(
          FoundationAndCompany,
          'Stichting en bedrijf',
          FoundationAndCompany,
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      return 'Waar zoek je een notaris voor?'
    },
  },
}
</script>
