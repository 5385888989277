<template>
  <div class="usp-row" :class="[colorVariant]">
    <span v-for="(usp, i) in usps" :key="i" class="usp-row__usp">
      <t-icon class="mr-2" :icon="uspIcon" :color="color" />{{ usp }}
    </span>
  </div>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'UspRow',

  mixins: [usesColor],

  props: {
    usps: {
      type: Array,
      default: () => ['Gratis', 'Snel', 'Vrijblijvend'],
    },

    color: {
      type: String,
      default: 'secondary',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      uspIcon: mdiCheck,
    }
  },
}
</script>
