<template>
  <WhatDeedsFormGroup :title="question" :choices="choices" v-on="$listeners" />
</template>

<script>
import WhatDeedsFormGroup from 'chimera/all/components/form/parts/whatDeeds/WhatDeedsFormGroup'
import {
  officeOfNotaryWeddingAndCohabitationConsumerNL,
  officeOfNotaryWillAndInheritanceConsumerNL,
} from 'chimera/officeOfNotary/service'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'PersonAndFamilyDeedFormPart',

  components: {
    WhatDeedsFormGroup,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('Testament', 'Testament', 'Testament', {
          service: officeOfNotaryWillAndInheritanceConsumerNL,
        }),
        new Selectable(
          'Notariële volmacht',
          'Notariële volmacht',
          'Notariële volmacht',
          {
            service: officeOfNotaryWillAndInheritanceConsumerNL,
          },
        ),
        new Selectable(
          'Verklaring van erfrecht',
          'Verklaring van erfrecht',
          'Verklaring van erfrecht',
          {
            service: officeOfNotaryWillAndInheritanceConsumerNL,
          },
        ),
        new Selectable('Schenking', 'Schenking', 'Schenking', {
          service: officeOfNotaryWillAndInheritanceConsumerNL,
        }),
        new Selectable(
          'Geregistreerd partnerschap',
          'Geregistreerd partnerschap',
          'Geregistreerd partnerschap',
          {
            service: officeOfNotaryWeddingAndCohabitationConsumerNL,
          },
        ),
        new Selectable(
          'Samenlevingscontract',
          'Samenlevingscontract',
          'Samenlevingscontract',
          {
            service: officeOfNotaryWeddingAndCohabitationConsumerNL,
          },
        ),
        new Selectable(
          'Huwelijkse voorwaarden',
          'Huwelijkse voorwaarden',
          'Huwelijkse voorwaarden',
          {
            service: officeOfNotaryWeddingAndCohabitationConsumerNL,
          },
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      return 'Welke akte heb je nodig?'
    },
  },
}
</script>
