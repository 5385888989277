<template>
  <WhatDeedsFormGroup :title="question" :choices="choices" v-on="$listeners" />
</template>

<script>
import WhatDeedsFormGroup from 'chimera/all/components/form/parts/whatDeeds/WhatDeedsFormGroup'
import { officeOfNotaryHouseAndMortgageConsumerNL } from 'chimera/officeOfNotary/service'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'HouseAndMortgageDeedFormPart',

  components: {
    WhatDeedsFormGroup,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('Hypotheekakte', 'Hypotheekakte', 'Hypotheekakte', {
          service: officeOfNotaryHouseAndMortgageConsumerNL,
        }),
        new Selectable(
          'Hypotheek oversluiten',
          'Hypotheek oversluiten',
          'Hypotheek oversluiten',
          {
            service: officeOfNotaryHouseAndMortgageConsumerNL,
          },
        ),
        new Selectable('Leveringsakte', 'Leveringsakte', 'Leveringsakte', {
          service: officeOfNotaryHouseAndMortgageConsumerNL,
        }),
        new Selectable(
          'Koopovereenkomst',
          'Koopovereenkomst',
          'Koopovereenkomst',
          {
            service: officeOfNotaryHouseAndMortgageConsumerNL,
          },
        ),
        new Selectable(
          'Afkopen van erfpacht',
          'Afkopen van erfpacht',
          'Afkopen van erfpacht',
          {
            service: officeOfNotaryHouseAndMortgageConsumerNL,
          },
        ),
        new Selectable(
          'Akte van verdeling',
          'Akte van verdeling',
          'Akte van verdeling',
          {
            service: officeOfNotaryHouseAndMortgageConsumerNL,
          },
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      return 'Welke akte heb je nodig?'
    },
  },
}
</script>
