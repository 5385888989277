<template>
  <div>
    <DisciplineFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DisciplineFormStep from '~/components/form/steps/discipline/DisciplineFormStep'

export default {
  name: 'ServiceDetailPage',

  components: {
    DisciplineFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type opdracht',
      path: '/offertes-aanvragen/dienst',
      progressValue: 20,
    }
  },
}
</script>
