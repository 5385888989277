<template>
  <CTAContainer :title="ctaTitle">
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <div class="form-part__row">
        <PostalField
          class="postal"
          :autofocus="false"
          @result="onResult"
          @change="onChange"
        />
        <FormStepSubmitButton id="submitPostal" text="Vergelijk nu" />
      </div>
    </t-form>
    <UspRow class="mt-4" />
  </CTAContainer>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import CTAContainer from 'chimera/all/components/CTAContainer'
import UspRow from 'chimera/all/components/UspRow'
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'
import ServiceDetailPage from '~/pages/offertes-aanvragen/dienst'

export default {
  name: 'PostalFormStep',

  components: {
    CTAContainer,
    PostalField,
    UspRow,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      ctaTitle: 'Vergelijk offertes van notarissen uit je regio',
    }
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(ServiceDetailPage)
    },
  },
}
</script>

<style lang="scss">
.form-part {
  &__row {
    display: flex;

    .postal {
      width: 50%;

      .t-text-field__wrapper {
        min-height: 60px;
      }
    }

    .formstep-btn {
      max-width: 50%;
      width: 50%;
      margin-left: 8px;
      min-width: auto;
      margin-top: 0 !important;
    }
  }
}
</style>
