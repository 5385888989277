<template>
  <WhatDeedsFormGroup :title="question" :choices="choices" v-on="$listeners" />
</template>

<script>
import { officeOfNotaryLegalEntitiesConsumerNL } from 'chimera/officeOfNotary/service'
import WhatDeedsFormGroup from 'chimera/all/components/form/parts/whatDeeds/WhatDeedsFormGroup'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'FoundationAndCompanyDeedFormPart',

  components: {
    WhatDeedsFormGroup,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('Oprichting', 'Oprichting', 'Oprichting', {
          service: officeOfNotaryLegalEntitiesConsumerNL,
        }),
        new Selectable(
          'Statuten wijziging',
          'Statuten wijziging',
          'Statuten wijziging',
          {
            service: officeOfNotaryLegalEntitiesConsumerNL,
          },
        ),
        new Selectable(
          'Aandelenoverdracht',
          'Aandelenoverdracht',
          'Aandelenoverdracht',
          {
            service: officeOfNotaryLegalEntitiesConsumerNL,
          },
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    question() {
      return 'Welke akte heb je nodig?'
    },
  },
}
</script>
