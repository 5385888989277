<template>
  <button
    id="scroll-more"
    v-track-click
    type="button"
    class="scroll-more"
    :class="[colorVariant]"
    @click="onClick"
  >
    <svg
      enable-background="new 0 0 63 63"
      viewBox="0 0 63 63"
      width="64"
      height="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        clip-rule="evenodd"
        cx="31.5"
        cy="31.5"
        fill-rule="evenodd"
        r="31.5"
      />
      <g fill="#fff" transform="translate(19 16)">
        <path
          clip-rule="evenodd"
          d="m18.8 4.1c.6-.6.6-1.6 0-2.2s-1.6-.6-2.2 0l-4.8 4.7-4.8-4.7c-.6-.6-1.6-.6-2.2 0-.4.3-.5.7-.5 1.1s.2.8.5 1.1l5.9 5.9c.6.6 1.6.6 2.2 0 0 0 5.9-5.9 5.9-5.9z"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="m18.8 12.1c.6-.6.6-1.6 0-2.2s-1.6-.6-2.2 0l-4.8 4.7-4.8-4.7c-.6-.6-1.6-.6-2.2 0-.3.3-.5.7-.5 1.1s.2.8.5 1.1l5.9 5.9c.6.6 1.6.6 2.2 0z"
          fill-rule="evenodd"
        />
        <g enable-background="new">
          <path
            d="m3.2 29.5-1.7-5.6c.1 1.1.1 1.9.1 2.3v3.3h-1.4v-7.1h2.1l1.7 5.5 1.8-5.5h2.1v7.1h-1.5v-3.4c0-.2 0-.3 0-.6s0-.8.1-1.7l-1.8 5.6h-1.5z"
          />
          <path
            d="m11.9 29.5c-.9 0-1.6-.2-2.1-.7s-.7-1.2-.7-2.1.2-1.6.7-2.1 1.1-.7 1.9-.7 1.4.2 1.8.7.6 1 .6 1.8v.7h-3.5c0 .4.1.8.4 1s.6.4 1 .4c.3 0 .6 0 .9-.1s.6-.2.9-.3v1.2c-.3.1-.5.2-.8.3-.3-.1-.6-.1-1.1-.1zm-.2-4.6c-.3 0-.6.1-.7.3-.2.2-.3.5-.3.9h2.1c0-.4-.1-.7-.3-.9s-.5-.3-.8-.3z"
          />
          <path
            d="m17.9 29.5c-.9 0-1.6-.2-2.1-.7s-.8-1.2-.8-2c0-.9.2-1.6.7-2.1s1.1-.7 1.9-.7 1.4.2 1.8.7.6 1 .6 1.8v.7h-3.5c0 .4.1.8.4 1s.6.4 1 .4c.3 0 .6 0 .9-.1s.6-.2.9-.3v1.2c-.3.1-.5.2-.8.3-.3-.2-.6-.2-1-.2zm-.3-4.6c-.3 0-.6.1-.7.3-.2.2-.3.5-.3.9h2.1c0-.4-.1-.7-.3-.9s-.4-.3-.8-.3z"
          />
          <path
            d="m24.3 23.9h.5l-.1 1.4c-.1 0-.3 0-.4 0-.5 0-.8.1-1.1.4-.3.2-.4.6-.4 1v2.8h-1.5v-5.5h1.1l.2.9h.1c.2-.3.4-.6.7-.7.3-.2.6-.3.9-.3z"
          />
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'ScrollMore',

  mixins: [usesColor],

  props: {
    selector: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: 'secondary',
    },
  },

  methods: {
    /**
     *
     */
    onClick() {
      const element = document.querySelector(this.selector)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>
