<template>
  <div>
    <ImageHeader id="header" class="header" scroll-selector="#how-it-works">
      <template #card>
        <PostalFormStep :progress-value="0" />
      </template>
    </ImageHeader>

    <HomeBlock id="how-it-works" title="Hoe werkt het?" color="secondary">
      <HowItWorks :steps="howItWorksSteps" />
    </HomeBlock>

    <HomeBlock id="review" title="Wat anderen over ons zeggen">
      <Reviews :reviews="reviews" />
    </HomeBlock>

    <HomeBlock
      id="faq"
      title="Dit zijn de vragen die vaak aan ons gesteld worden"
      horizontal
      color="secondary"
    >
      <FAQ :items="faq" />
    </HomeBlock>

    <HomeBlock>
      <Perks
        title="Bespaar op je notariskosten"
        subtitle="Vergelijk notarissen voor je beslist."
        :animation="perksAnimation"
      />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import ImageHeader from 'chimera/all/components/header/ImageHeader'
import HowItWorks from 'chimera/all/components/HowItWorks'
import Reviews from 'chimera/all/components/review/Reviews'
import HomeBlock from 'chimera/all/components/HomeBlock'
import FAQ from 'chimera/all/components/faq/FAQ'
import Perks from 'chimera/all/components/Perks'
import perksAnimation from '@/assets/animations/perks.json'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    FAQ,
    HomeBlock,
    HowItWorks,
    ImageHeader,
    PostalFormStep,
    Reviews,
    Perks,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat kost een notaris? Vergelijk offertes',
      headDescription:
        'Op zoek naar een notaris? Vind en vergelijk de beste notarissen uit jouw regio.',
      path: '/',
      howItWorksSteps: [
        {
          title: 'Vertel ons wat je zoekt',
          text: 'Selecteer de dienst die je nodig hebt en vul de aanvraag aan met jouw wensen.',
          imagePath: require('chimera/all/assets/images/how-it-works/questions.svg'),
        },
        {
          title: 'Wij vinden de beste match',
          text: 'Je aanvraag komt bij ons binnen en wij gaan voor je op zoek naar de notarissen die het beste bij je opdracht aansluiten.',
          imagePath: require('chimera/all/assets/images/how-it-works/likeness.svg'),
        },
        {
          title: 'Kom in contact en ontvang offertes',
          text: 'De geselecteerde notarissen sturen je een offerte-op-maat. Je ontvangt de offertes en kunt de prijzen vergelijken. Zo maak je simpel en snel een weloverwogen keuze.',
          imagePath: require('chimera/all/assets/images/how-it-works/group-record.svg'),
        },
        {
          title: 'Vergelijk en kies',
          text: 'Kies de notaris die het beste bij jou past. De notaris neemt het werk voor je uit handen.',
          imagePath: require('chimera/all/assets/images/how-it-works/contract.svg'),
        },
      ],
      reviews: [
        {
          id: 'review_1',
          rating: 4,
          name: 'Remi van der Kuil',
          text: 'De gevonden notaris heeft ons goed geholpen bij het opstellen van ons testament.',
        },
        {
          id: 'review_2',
          rating: 4,
          name: 'Noelle Molendijk',
          text: 'Erg makkelijk en snel geregeld. Ik heb zo een prima notaris gevonden.',
        },
        {
          id: 'review_3',
          rating: 3.5,
          name: 'Marion Klomp',
          text: 'Helaas maar één notaris gevonden, deze heeft ons echter super geholpen dus we zijn tevreden.',
        },
      ],
      faq: [
        {
          active: false,
          title: 'Waar moet ik op letten bij het vergelijken van notarissen?',
          details: `
           <p>
            Een notaris is sinds 1999 vrij om zijn eigen tarieven te bepalen. Als je op de kosten wil besparen is het dus zeker de moeite om verschillende offertes aan te vragen. Kijk vervolgens goed naar de kosten per akte en of alle bijkomende kosten in alle offertes beschreven staan.
          </p>
          <p>
            Een goed voorbeeld van uiteenlopende kosten zijn de kadasterkosten. Notarissen brengen deze vaak voor de traditionele €137,50 in rekening terwijl ze de hypotheekakte vaak tegen een lager KIK-tarief van €78,50 kunnen inschrijven.
          </p>
          `,
        },
        {
          active: false,
          title: 'Wat gebeurt er met mijn gegevens als ik een aanvraag invul?',
          details:
            'Jouw persoonlijke gegevens zijn bij ons in veilige handen, ze zijn van belang om de juiste notarissen in jouw regio aan je te koppelen. Je gegevens komen alleen bij de geselecteerde notarissen terecht en zullen nooit verder gedeeld worden.',
        },
        {
          active: false,
          title: 'Zijn er kosten verbonden aan een aanvraag?',
          details:
            'Nee, een aanvraag indienen is altijd gratis en vrijblijvend!',
        },
      ],
      perksAnimation,
    }
  },
}
</script>

<style lang="scss">
.image-header {
  height: 560px;

  @screen sm {
    height: 700px;
  }

  &__bg {
    height: 400px;
    background-image: url('~../static/images/header/hero-sm.jpg');

    @screen sm {
      height: 500px;
    }

    @screen md {
      background-image: url('~../static/images/header/hero-md.jpg');
    }

    @screen lg {
      background-image: url('~../static/images/header/hero-lg.jpg');
      background-position: 50% 15% !important;
    }

    @screen xl {
      background-image: url('~../static/images/header/hero-xl.jpg');
      background-position: 50% 25% !important;
    }
  }
}
</style>
