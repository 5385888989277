<template>
  <div class="how-it-works" :class="[colorVariant]">
    <t-stepper :color="color" :steps="steps" />
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'HowItWorks',

  mixins: [usesColor],

  props: {
    steps: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: '',
    },
  },
}
</script>
