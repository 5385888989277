<template>
  <div class="review">
    <div class="review__top">
      <span class="review__name">
        {{ name }}
      </span>

      <t-rating :value="rating" class="review__rating" />
    </div>
    <p class="review__body">"{{ text }}"</p>
    <div class="review__divider" />
  </div>
</template>

<script>
export default {
  name: 'Review',

  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },

    rating: {
      type: Number,
      default: 0,
      required: true,
    },

    text: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
