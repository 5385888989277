<template>
  <div>
    <FoundationAndCompanyDeedFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FoundationAndCompanyDeedFormStep from '~/components/form/steps/deed/FoundationAndCompanyDeedFormStep'

export default {
  name: 'FoundationAndCompanyDeedPage',

  components: {
    FoundationAndCompanyDeedFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Akte(s)',
      path: '/offertes-aanvragen/akte-stichting-en-bedrijf',
      progressValue: 40,
    }
  },
}
</script>
