<template>
  <div class="button-column">
    <t-btn
      v-for="button of buttons"
      :id="button.id"
      :key="button.id"
      class="button-column__item t-btn--block t-btn--x-large"
      color="primary"
      @click="onClick(button)"
    >
      {{ button.label }}
    </t-btn>
  </div>
</template>

<script>
export default {
  name: 'ButtonColumnFormPart',

  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },

  methods: {
    /**
     * Emit selected item.
     *
     * @param {object}  button
     */
    onClick(button) {
      this.$emit('input', button)
      this.$emit('change', button)
      this.$emit('click', button)
    },
  },
}
</script>
