<template>
  <div class="cta-container">
    <h1 class="cta-container__title">
      {{ title }}
    </h1>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CTAContainer',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
