<template>
  <section>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <PersonAndFamilyDeedFormPart @result="onResult" @change="onChange" />
      <FormStepSubmitButton id="submitDeed" text="Volgende" />
    </t-form>
  </section>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PersonAndFamilyDeedFormPart from 'chimera/officeOfNotary/components/form/parts/deed/PersonAndFamilyDeedFormPart'
import CommentsFormPage from '~/pages/offertes-aanvragen/opmerkingen'

export default {
  name: 'PersonAndFamilyDeedFormStep',

  components: {
    PersonAndFamilyDeedFormPart,
  },

  extends: AbstractFormStep,

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(CommentsFormPage)
    },
  },
}
</script>
