<template>
  <div>
    <PersonAndFamilyDeedFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import PersonAndFamilyDeedFormStep from '~/components/form/steps/deed/PersonAndFamilyDeedFormStep'

export default {
  name: 'PersonAndFamilyDeedPage',

  components: {
    PersonAndFamilyDeedFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Akte(s)',
      path: '/offertes-aanvragen/akte-persoon-en-familie',
      progressValue: 40,
    }
  },
}
</script>
