<template>
  <div class="reviews">
    <Review
      v-for="(review, i) in reviews"
      :id="review.id"
      :key="i"
      v-track-visibility.once
      :name="review.name"
      :rating="review.rating"
      :text="review.text"
    />
  </div>
</template>

<script>
import Review from 'chimera/all/components/review/Review'

export default {
  name: 'Reviews',

  components: {
    Review,
  },

  props: {
    reviews: {
      type: Array,
      required: true,
    },
  },
}
</script>
