<template>
  <div>
    <HouseAndMortgageDeedFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import HouseAndMortgageDeedFormStep from '~/components/form/steps/deed/HouseAndMortgageDeedFormStep'

export default {
  name: 'HouseAndMortgageDeedPage',

  components: {
    HouseAndMortgageDeedFormStep,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Akte(s)',
      path: '/offertes-aanvragen/akte-huis-en-hypotheek',
      progressValue: 40,
    }
  },
}
</script>
