<template>
  <div class="faq" :class="[colorVariant]">
    <t-expansion-panel
      id="faq-expansion-panel"
      :color="color"
      v-bind="{ ...$props, ...$attrs }"
    />
  </div>
</template>

<script>
import TExpansionPanel from 'chimera/all/components/elements/TExpansionPanel'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'FAQ',

  components: {
    TExpansionPanel,
  },

  mixins: [usesColor],

  inheritAttrs: false,
}
</script>
