<template>
  <header class="image-header container">
    <div
      class="image-header__bg"
      :class="{ 'image-header__overlay': hasText }"
      aria-label="header image"
    >
      <div class="image-header__text">
        <slot name="text" />
      </div>
    </div>

    <t-card class="image-header__card">
      <slot name="card" />
      <ScrollMore v-if="!hideScrollButton" :selector="scrollSelector" />
    </t-card>
  </header>
</template>

<script>
import ScrollMore from 'chimera/all/components/header/ScrollMore'

export default {
  name: 'ImageHeader',

  components: {
    ScrollMore,
  },

  props: {
    hideScrollButton: {
      type: Boolean,
      default: false,
    },

    scrollSelector: {
      type: String,
      default: '',
    },
  },

  computed: {
    /**
     * Check if text slot has content
     *
     * @returns {boolean}
     */
    hasText() {
      return !!this.$slots.text && !!this.$slots.text[0]
    },
  },
}
</script>
